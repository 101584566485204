<template>
  <v-container>
    <page-title
      previous-location="/teachers/courses?tab=package"
      :page-title="!$language ? 'Edit Package' : 'প্যাকেজ এডিট করুন'"
      class="mt-5"
    ></page-title>
    <v-card class="ma-5 pa-5" style="border-radius: 10px 10px">
      <package-edit :package-id="packageId"></package-edit>
    </v-card>
  </v-container>
</template>

<script>
import PackageEdit from "../../modules/package/PackageEdit.vue";
export default {
  name: "PackageEditPage",
  components: { PackageEdit },
  props: {
    packageId: {
      type: String,
      required: true
    }
  }
};
</script>

<style></style>
