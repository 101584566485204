var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c("page-title", {
        staticClass: "mt-5",
        attrs: {
          "previous-location": "/teachers/courses?tab=package",
          "page-title": !_vm.$language ? "Edit Package" : "প্যাকেজ এডিট করুন",
        },
      }),
      _c(
        "v-card",
        {
          staticClass: "ma-5 pa-5",
          staticStyle: { "border-radius": "10px 10px" },
        },
        [_c("package-edit", { attrs: { "package-id": _vm.packageId } })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }